import * as React from 'react';
import { NextPage } from 'next';
import { Dispatch } from 'redux';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useSelector } from 'react-redux';

import config from 'config';
import { Home } from 'components/PrivateMarketplace/Home/Home';
import { PrivateMarketplaceLayout } from 'components/PrivateMarketplaceLayout/PrivateMarketplaceLayout';
import { BookingWidgetLayout } from 'components/BookingWidgetLayout/BookingWidgetLayout';
import { ProductList } from 'components/ProductList/ProductList';
import { ReduxState, wrapper } from 'ducks';
import {
  fetchSettingsServerSide,
  fetchProductSummariesServerSide,
  fetchCustomPagesServerSide,
  fetchCustomTopPageServerSide,
  fetchPartnershipProductSummariesServerSide,
  fetchPartnershipPackageProductSummariesServerSide,
} from 'ducks/server';
import { fetchConfigForHostname } from 'lib/util/fetchConfigForHostname';
import { withSSR } from 'withSSR';
import { getI18nServerSideProps } from 'i18n';
import { detectLocale } from 'i18n-server';
import { absoluteUrl } from 'lib/util/absoluetUrl';
import { selectPMPEnabled } from 'ducks/server/settings';
import { selectLanguageOptions } from 'ducks/server/settings';
import { languageOptionMap } from 'lib/util/i18nHelpers';
import { findPMPLinkForLocale } from 'lib/util/findPMPLinkForLocale';
import { CustomTopPage } from 'components/CustomTopPage/CustomTopPage';

interface Props {
  apiKey: string;
  host: string;
}

const Top: NextPage<Props> = ({ host }) => {
  const router = useRouter();

  const pmpEnabled = useSelector(selectPMPEnabled);
  const shouldUseCustomTopPage = useSelector(
    (state: ReduxState) => state.server.settings.all?.is_custom_top_page_enabled
  );
  const topPageData = useSelector((state: ReduxState) => state.server.customTopPage.page);

  return (
    <>
      <Head>
        <meta property="og:url" content={`${host}${router.asPath}`} />
        <meta property="og:type" content={'website'} />
        <meta name="twitter:card" content={'summary_large_image'} />
      </Head>
      {shouldUseCustomTopPage && topPageData ? (
        <CustomTopPage topPageData={topPageData} />
      ) : pmpEnabled ? (
        <PrivateMarketplaceLayout>
          <Home />
        </PrivateMarketplaceLayout>
      ) : (
        <BookingWidgetLayout>
          <ProductList />
        </BookingWidgetLayout>
      )}
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async ({ req, res, store }) => {
  const hostname = req.headers?.host?.split(':')[0] || '';
  const { apiKey, lang } = await fetchConfigForHostname(hostname);

  const dispatch: Dispatch<any> = store.dispatch;

  let i18nProps = {};

  let locale = lang;
  if (locale) {
    // If lang is initialized in config, this is a single-language PMP

    await Promise.all([
      dispatch(fetchSettingsServerSide(apiKey, '', locale, `https://${hostname}`)),
      dispatch(fetchProductSummariesServerSide(apiKey, locale)),
      dispatch(fetchCustomPagesServerSide(apiKey, locale)),
    ]);

    const isCustomTopPageEnabled = store.getState().server.settings.all?.is_custom_top_page_enabled;

    if (isCustomTopPageEnabled) {
      await dispatch(fetchCustomTopPageServerSide(apiKey, locale));
    }

    i18nProps = await getI18nServerSideProps(locale);
  } else {
    locale = await detectLocale(req, res);

    // Fetch settings using detected language
    await dispatch(fetchSettingsServerSide(apiKey, '', locale));

    // Verify that the detected language is in the supplier's supported languages. If it isn't,
    // default to the first supported language and refetch settings.
    const langOptions = selectLanguageOptions(store.getState());
    if (langOptions.length > 0 && !langOptions.some((langOpt) => langOpt.iso === locale)) {
      locale = langOptions[0].iso;
      await dispatch(fetchSettingsServerSide(apiKey, '', locale));
    }

    const promises = [
      dispatch(fetchProductSummariesServerSide(apiKey, locale)),
      dispatch(fetchCustomPagesServerSide(apiKey, locale)),
    ];

    const isCustomTopPageEnabled = store.getState().server.settings.all?.is_custom_top_page_enabled;

    if (isCustomTopPageEnabled) {
      promises.push(dispatch(fetchCustomTopPageServerSide(apiKey, locale)));
    }
    await Promise.all(promises);

    i18nProps = await getI18nServerSideProps(locale);

    if (langOptions.length === 1) {
      (req as any).locale = langOptions[0].iso;
    } else {
      const productSummaries = store.getState().server.productSummaries.all;
      if (productSummaries.length > 0) {
        const sourceLanguage = productSummaries[0]?.source_language || 'EN_US';
        (req as any).locale = languageOptionMap[sourceLanguage]?.iso;
      }
    }
  }

  // If there are private marketplaces but we are viewing as booking widget, redirect to a private marketplace.
  const pmpEnabled = !!store.getState().server.settings.all.private_marketplace;
  const pmpLinks = store.getState().server.settings.all.private_marketplace_links;
  if (!pmpEnabled && (pmpLinks ?? []).length > 0) {
    const pmpLink = findPMPLinkForLocale(pmpLinks, locale);
    if (pmpLink) {
      return {
        redirect: {
          destination: pmpLink.url,
          permanent: false,
        },
      };
    }
  }

  if (config.enablePartnership) {
    const settings = store.getState().server.settings.all;
    if (settings.corresponding_organization_booking_widget_api_key) {
      await dispatch(
        fetchPartnershipProductSummariesServerSide(
          settings.corresponding_organization_booking_widget_api_key,
          locale
        )
      );
    }
    if (settings.should_show_partnership_package_product) {
      await dispatch(fetchPartnershipPackageProductSummariesServerSide(apiKey, locale));
    }
  }

  (req as any).locale = locale;

  return {
    props: {
      apiKey,
      host: absoluteUrl(req),
      ...i18nProps,
    },
  };
});

export default withSSR()(Top);
